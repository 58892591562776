import { defineStore } from 'pinia';
import { useLayoutStore } from '~/stores/layout';
import { useGlobalsStore } from '~/stores/globals';
import { useGamesStore } from '~/stores/games';
import { API_PREFIX } from '~/constants/apiConfiguration';
import { INFO_MESSAGE_PAGE_PARAMS } from '~/constants/infoMessages';
import { REQUIRED_AUTH_PAGES_WITH_CHILDREN } from '~/constants/general';
import {
 LOCAL_STORAGE,
 COOKIE,
} from '~/constants/storedPropertiesNames';
import type {
  AuthStoreState,
  ResetPasswordData,
  RestorePasswordData,
} from '~/types/stores/auth';
import type {
  AuthData,
  AuthRequestBody,
  MfaData,
} from '~/types/pages/login';
import type {
  RegistrationData,
  RegistrationRequestBody,
} from '~/types/pages/registration';
import type {
  FetchUserBalanceResponse,
  FetchUserBonusesCountResponse,
  FetchUserBonusesResponse,
  FetchUserProfileResponse,
  UserDataSqwonChat,
  CheckVerificationDocumentsResponse,
  LoginResponse,
} from '~/types/general/user';

export const useAuthStore = defineStore({
  id: 'auth',
  state: (): AuthStoreState => ({
    userData: {
      locale: useCookie(COOKIE.activeLanguage)?.value ?? useNuxtApp().$i18n.defaultLocale,
      loggedIn: Boolean(useCookie(COOKIE.userPlayerId)?.value && useCookie(COOKIE.jSessionIdFE)?.value) ?? false,
      showTermsAndConditions: false,
      needDocumentsVerification: false,
    },
    showMFAAuth: false,
  }),
  getters: {
    getUserData: (state) => state.userData,
    getShowMFAAuth: (state) => state.showMFAAuth,
    getUserActiveBalance: (state) => state.userData?.activeBalance,
  },
  actions: {
    // TODO: split auth data for more readable
    async registration(registrationData: RegistrationData) {
      const body: RegistrationRequestBody = {
        ...registrationData.registrationForm,
        language: this.getUserData.locale.toUpperCase(),
        ...(localStorage.getItem(LOCAL_STORAGE.referrerAz)
          ? { referrerAz: decodeURIComponent(localStorage?.getItem(LOCAL_STORAGE.referrerAz) ?? '') }
          : {}),
        ...(localStorage.getItem(LOCAL_STORAGE.pageQueryString)
          ? { request: decodeURIComponent(localStorage?.getItem(LOCAL_STORAGE.pageQueryString) ?? '') }
          : {}),
      };
      const headers = { 'captcha-response': registrationData['captcha-response'] };

      try {
        await useMyFetch().post(`${API_PREFIX.player}/register`, {
          body,
          headers,
        });
        localStorage.removeItem(LOCAL_STORAGE.referrerAz);
        localStorage.removeItem(LOCAL_STORAGE.pageQueryString);
      } catch (error) {
        throw error;
      }
    },
    async login(authData: AuthData) {
      const { setNotification } = useGlobalsStore();

      const body: AuthRequestBody = {
        ...authData.authForm,
        language: this.getUserData.locale,
      };
      const headers = { 'captcha-response': authData['captcha-response'] };
      const {
        statusCode,
        data,
      } = await useMyFetch().post(`${API_PREFIX.player}/signin`, {
        body,
        headers,
      }) as LoginResponse;

      if (statusCode === 202) {
        this.showMFAAuth = true;
      } else {
        localStorage.removeItem(LOCAL_STORAGE.referrerAz);
        localStorage.removeItem(LOCAL_STORAGE.pageQueryString);
        await this.initializeUser();

        if (data.return.firstLogin) {
          setNotification({
            dictionaryKeyTitle: 'general_welcome',
            dictionaryKeyText: 'notifications_welcome',
          });
        }

        this.initChat(true);
      }
    },
    async logout() {
      const {
        clearFreeSpinsInterval,
        removeAllNotificationsExceptCookie,
      } = useGlobalsStore();
      const { setPageLoader } = useLayoutStore();
      const { clearGameProviders } = useGamesStore();
      const route = useRoute();
      const router = useRouter();
      const { $i18n } = useNuxtApp();

      try {
        setPageLoader(true);
        const userPlayerIdCookie = useCookie(COOKIE.userPlayerId);
        const jSessionIdFECookie = useCookie(COOKIE.jSessionIdFE);

        clearGameProviders();
        clearFreeSpinsInterval();
        await useMyFetch().post(`${new URL(useBaseAppUrl().api).origin}/back/j_spring_security_logout`);
        useLocalStorageObject.remove(LOCAL_STORAGE.bonusActivationCache);
        this.userData.loggedIn = false;
        this.userData = {
          locale: this.userData.locale,
          loggedIn: false,
          showTermsAndConditions: false,
          needDocumentsVerification: false,
        };
        userPlayerIdCookie.value = null;
        jSessionIdFECookie.value = null;
        const parentPathIndex = Number(this.getUserData.locale !== $i18n.defaultLocale) + 1;
        const parentPath = route.path.split('/')[parentPathIndex];

        removeAllNotificationsExceptCookie();
        //TODO: unificate info messages params with other pages params
        if (
          route.params?.pageType === INFO_MESSAGE_PAGE_PARAMS.login ||
          route.params?.pageType === INFO_MESSAGE_PAGE_PARAMS.blockLite ||
          Object.keys(REQUIRED_AUTH_PAGES_WITH_CHILDREN).includes(parentPath)
        ) {
          nextTick(async () => {
            await router.push(useLocalePrefixForHref('/'));
          });
        } else {
          nextTick(() => {
            window.location.reload();
          });
        }
        this.initChat(true);
      } catch(error) {
        throw(error);
      } finally {
        setPageLoader(false);
      }
    },
    async loginWithMfa({ code }: MfaData) {
      const urlencodedData = `code=${code}`;

      await useMyFetch().post(`${API_PREFIX.playerProfile}/mfa/authenticate`, {
        headers: { 'content-type': 'application/x-www-form-urlencoded; charset=UTF-8' },
        body: urlencodedData,
      });
      localStorage.removeItem(LOCAL_STORAGE.referrerAz);
      localStorage.removeItem(LOCAL_STORAGE.pageQueryString);
      await this.initializeUser();
      this.initChat(true);
      this.showMFAAuth = false;
    },
    async backToLogin() {
      this.showMFAAuth = false;
    },
    async initializeUser() {
      const { clearGameProviders } = useGamesStore();

      try {
        clearGameProviders(),
        await Promise.all([
          this.fetchUserProfileData(),
          this.checkVerificationDocuments(),
          this.fetchUserBonusesCount(),
          this.fetchUserBalances(),
        ]);
      } catch(error) {
        throw(error);
      }
    },
    async fetchUserBalances() {
      try {
        const { data } = await useMyFetch().get(`${API_PREFIX.account}/info`) as FetchUserBalanceResponse;
        const bonuses = await this.fetchUserBonuses();
        const balances = data.return;

        balances.forEach((balance) => {
          if (bonuses.hasOwnProperty(balance.currency)){
            balance.bonusAmount = bonuses[balance.currency];
          }
        });
        this.userData.balances = balances;
        this.userData.activeBalance = balances.find((balance) => balance.active);
      } catch(error) {
        throw error;
      }
    },
    async fetchUserProfileData() {
      try {
        const { data } = await useMyFetch().get(`${API_PREFIX.playerProfile}/profile`) as FetchUserProfileResponse;
        const userPlayerIdCookie = useCookie(COOKIE.userPlayerId);
        const jSessionIdFECookie = useCookie(COOKIE.jSessionIdFE);

        this.userData = {
          ...this.userData,
          ...data,
          loggedIn: true,
        };
        userPlayerIdCookie.value = this.userData?.playerId?.toString() ?? '';
        jSessionIdFECookie.value = this.userData?.sessionId?.toString() ?? '';
      } catch(error) {
        throw error;
      }
    },
    async fetchUserBonusesCount() {
      try {
        const { data } = await useMyFetch().get(
          `${API_PREFIX.promotions}/available/count`,
          { query: { forceNoCache: 'true' } },
        ) as FetchUserBonusesCountResponse;

        this.userData.bonusesCount = data.count.toString();
      } catch(error) {
        throw error;
      }
    },
    async fetchUserBonuses() {
      try {

        const { data } = await useMyFetch().get(`${API_PREFIX.promotions}/bonuses/allsums`) as FetchUserBonusesResponse;

        return data;
      } catch(error) {
        throw error;
      }
    },
    async checkVerificationDocuments() {
      try {
        const { data } = await useMyFetch().get(
          `${API_PREFIX.playerVerification}/v1/verification-requested`,
        ) as CheckVerificationDocumentsResponse;

        this.userData.needDocumentsVerification = data;
      } catch (error) {
        throw(error);
      }
    },
    async restorePassword(restorePasswordData: RestorePasswordData) {
      try {
        await useMyFetch().post(`${API_PREFIX.password}/reset/email/send-link`, {
          headers: { 'captcha-response': restorePasswordData['captcha-response'] },
          body: { email: restorePasswordData.email },
        });
      } catch (error) {
        throw error;
      }
    },
    async resetPassword(resetPasswordData: ResetPasswordData) {
      try {
        await useMyFetch().post(`${API_PREFIX.password}/reset/email/set-password`, {
          headers: {
            'captcha-response': resetPasswordData['captcha-response'],
            'reset-password-token': resetPasswordData['token'],
          },
          body: { password: resetPasswordData.password },
        });
      } catch (error) {
        throw error;
      }
    },
    setAppLocale(locale: string) {
      this.userData.locale = locale;
      this.initChat(true);
    },
    initChat(isReinit = false) {
      // TODO: check is it possible to simply extend userData in future
      const userDataSqwonChat: UserDataSqwonChat = this.userData.loggedIn
        ? {
          locale: this.userData.locale,
          project: 'jozz',
          clientId: this.userData.playerId,
          email: this.userData.email,
          username: this.userData.login,
          fio: this.userData.firstName + ' ' + this.userData.lastName,
          levelStatus: this.userData.levelStatus,
          ipLastAuth: this.userData.ipLastAuth,
          depositStatus: this.userData.depositStatus,
          isTrusted: this.userData.trusted,
          profileCountry: this.userData.registrationCountry,
        }
        : {
          locale: this.getUserData.locale,
          project: 'jozz',
        };

      useSqwonChat(userDataSqwonChat, isReinit);
    },
    deleteChat() {
      useSqwonChat();
    },
  },
});
